<template lang="html">
  <div>
    <template>
      <div
        id="data-list-list-view"
        class="data-list-container"
      >
        <vs-row class="pt-6">
          <vs-col
            vs-w="6"
            vs-type="flex"
          >
            <vs-button
              class="mb-1"
              color="primary"
              type="gradient"
              icon-pack="material-icons"
              icon="fast_rewind"
              @click="$router.push({name: 'User'}).catch(() => {})"
            > {{ $t('Users') }}
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-table
          search
          max-items="5"
          pagination
          :data="trashedUsers"
        >
          <template slot="thead">
            <vs-th sort-key="id">
              {{ $t('ID') }}
            </vs-th>
            <vs-th sort-key="name">
              {{ $t('Identity') }}
            </vs-th>
            <vs-th sort-key="email">
              {{ $t('Contact') }}
            </vs-th>
            <vs-th sort-key="status">
              {{ $t('status') }}
            </vs-th>
            <vs-th sort-key="created_at">
              {{ $t('Created') }} / {{ $t('Updated') }} / {{ $t('Deleted') }}
            </vs-th>
            <vs-th>{{ $t('Options') }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr
              v-for="(tr, indextr) in data"
              :key="indextr"
              :data="tr"
            >
              <vs-td :data="tr.id">
                <vx-badge
                  color="light-primary"
                >
                  {{ tr.id }}
                </vx-badge>
              </vs-td>
              <vs-td :data="tr.name">
                <div style="width: max-content">
                  <span
                    style="color: rgba(var(--vs-primary), 1);"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'address-card']"
                    />
                  </span>
                  <span>
                    {{ tr.name }}</span>
                  <span
                    class="ml-1"
                  >{{ tr.last_name }}</span>
                </div>
                <div style="width: max-content">
                  <span
                    style="color: rgba(var(--vs-primary), 1);"
                  >
                    <font-awesome-icon
                      color="primary"
                      :icon="['fas', 'passport']"
                    />
                  </span>
                  {{ tr.tc }}
                </div>
              </vs-td>
              <vs-td :data="tr.email">
                <VxTooltip
                  color="primary"
                >
                  <template #title>
                    {{ $t('Mail') }}
                  </template>
                  <template #text>
                    {{ $t('Click to send email') }}
                  </template>
                  <span
                    @click="SendEmail(tr.email)"
                  >
                    <fa-icon
                      pack="fas"
                      icon="envelope"
                      color="primary"
                    />
                    {{ tr.email }}
                  </span>
                </VxTooltip>
                <VxTooltip
                  color="primary"
                >
                  <template #title>
                    {{ $t('Call') }}
                  </template>
                  <template #text>
                    {{ $t('Click to Call') }}
                  </template>

                  <span @click="MakeCall(tr.mobile)"> <fa-icon
                    pack="fas"
                    icon="phone"
                    color="primary"
                  /> {{ tr.mobile }}</span>
                </VxTooltip>
              </vs-td>
              <vs-td :data="tr.status">
                <div style="width: max-content">
                  <VxTooltip
                    :color="tr.status==='Active'?'success':tr.status==='Blocked'?'danger':'warning'"
                  >
                    <template #title>
                      {{ $t('Status') }}
                    </template>
                    <template #text>
                      <span v-if="tr.status==='Active'"> {{ $t('This User is active') }}</span>
                      <span v-else>{{ $t(tr.blocked_reason) }}</span>
                    </template>
                    <vx-badge
                      pack="far"
                      :icon="tr.status==='Active'?'check-circle':tr.status==='Blocked'?'times-circle':'pause-circle'"
                      :color="tr.status==='Active'?'light-success':tr.status==='Blocked'?'light-danger':'light-warning'"
                      :icon-color-bg="tr.status==='Active'?'success':tr.status==='Blocked'?'danger':'warning'"
                      icon-color="null"
                    >
                      {{ $t(tr.status) }}
                    </vx-badge>
                  </VxTooltip>
                </div>
              </vs-td>
              <vs-td :data="tr.CreatedAt">
                <div style="width: max-content">

                  <VxTooltip
                    color="success"
                  >
                    <template #title>
                      {{ $t('Created_At') }}
                    </template>
                    <template #text>
                      {{ tr.created_at }}
                    </template>
                    <vx-badge
                      pack="far"
                      icon="calendar-plus"
                      color="light-success"
                      icon-color-bg="success"
                      icon-color="null"
                    >
                      {{ tr.created_from }}
                    </vx-badge>
                  </VxTooltip>
                </div>
                <div
                  style="width: max-content"
                  class="mt-1"
                >
                  <VxTooltip
                    color="warning"
                  >
                    <template #title>
                      {{ $t('Updated_At') }}
                    </template>
                    <template #text>
                      {{ tr.updated_at }}
                    </template>
                    <vx-badge
                      pack="fas"
                      icon="calendar-alt"
                      color="light-warning"
                      icon-color-bg="warning"
                      icon-color="null"
                    >
                      {{ tr.updated_from }}
                    </vx-badge>
                  </VxTooltip>
                </div>
                <div
                  style="width: max-content"
                  class="mt-1"
                >
                  <VxTooltip
                    color="danger"
                  >
                    <template #title>
                      {{ $t('Deleted_At') }}
                    </template>
                    <template #text>
                      {{ tr.deleted_at }}
                    </template>
                    <vx-badge
                      pack="far"
                      icon="calendar-times"
                      color="light-danger"
                      icon-color-bg="danger"
                      icon-color="null"
                    >
                      {{ tr.deleted_from }}
                    </vx-badge>
                  </VxTooltip>
                </div>
              </vs-td>
              <vs-td :data="tr.id">
                <a
                  class="ml-1 mr-1"
                  @click="RestoreUserAlert(tr.id)"
                >
                  <font-awesome-icon
                    color="rgb(var(--vs-success))"
                    :icon="['fas', 'trash-restore']"
                  />
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </template>
    <div>
      <b-card
        :title="$t('Explanation')"
        class="mt-10"
      >
        <vs-row>
          <vs-col
            class="mb-3"
            vs-w="3"
            vs-sm="6"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >

            <vx-badge
              pack="far"
              icon="calendar-plus"
              color="light-success"
              icon-color-bg="success"
              icon-color="null"
            >
              {{ $t('Created At') }}
            </vx-badge>
          </vs-col>
          <vs-col
            class="mb-3"
            vs-w="3"
            vs-sm="6"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vx-badge
              pack="fas"
              icon="calendar-alt"
              color="light-warning"
              icon-color-bg="warning"
              icon-color="null"
            >
              {{ $t('Updated At') }}
            </vx-badge>
          </vs-col>
          <vs-col
            class="mb-3"
            vs-w="3"
            vs-sm="6"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vx-badge
              pack="far"
              icon="calendar-times"
              color="light-danger"
              icon-color-bg="danger"
              icon-color="null"
            >

              {{ $t('Deleted At') }}
            </vx-badge>
          </vs-col>
          <vs-col
            class="mb-3"
            vs-w="3"
            vs-sm="6"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vx-badge
              pack="fas"
              icon="trash-restore"
              color="light-success"
              icon-color-bg="success"
              icon-color="null"
            >

              {{ $t('Restore User') }}
            </vx-badge>
          </vs-col>
        </vs-row>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
  },
  data: () => ({
    restoredUser:'',
  }),
  watch: {},
  computed: {
    trashedUsers() {
      return this.$store.getters["user/trashedUsers"]
    },
  },
  methods: {
    RestoreUserAlert($val) {
      this.restoredUser = $val
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('Confirm Restore'),
        text: this.$t('Are you sure you want to restore this User'),
        accept: this.acceptAlert,
        acceptText: this.$t('Restore'),
        cancelText: this.$t('Cancel')
      })
    },
    acceptAlert() {
      this.$store.dispatch('user/RestoreUser',this.restoredUser)
    },
    MakeCall(tel) {
      window.location.href = `tel://${tel}`;
    },
    SendEmail(email) {
      window.location.href = `mailto://${email}`;
    },
  },

  created() {
    this.$store.dispatch('user/GetTrashedData')
  }

}
</script>
<style>
.select-label-placeholder {
  position: absolute;
}

</style>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}


.vs-sidebar {
  z-index: 400000;
}
</style>
